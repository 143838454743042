export var ROUTES = {
    news: "/news/",
    events: "/events/",
    articles: "/articles/",
    vacancy: "/vacancy/",
    discount: "discount/",
    create_vacancy: "vacancy/Create",
    vacancy_refresh: "vacancy/Refresh/:id",
    open_news_page: "/news/:id/",
    open_events_page: "/events/:id/",
    open_articles_page: "/articles/:id/",
    search_result: "/search_result_page",
    company_profile: "/company_profile/",
    expert_profile: "/expert_profile",
    user_profile: "/user_profile",
    discont_profile: "/discont_profile",
    admin: "/admin",
    companies_page: "/companies_page",
    companies_serch_page: "/companies_serch_page",
    discontProfile: "/discontProfile/:id",
    companyProfile: "/companyProfile/:id/:content/",
    userProfile: "/userProfile/:id/:content",
    expertProfile: "/expertProfile/:id/:content",
    discont_create: "/discont/create/",
    discont_editDiscont: "/discont/editDiscont/:id",
    create: "/create",
    test_moder: "/test_moder/",
    test_moder_id: "/test_moder/:id",
    about: "/about/:id",
    notifications: "notifications/",
    resetPasswordPage: "/resetPasswordPage",
    AuthSocial: "/AuthSocial/",
    emailConfirmation: "/emailConfirmation",
    vacancy_id: "/vacancy/:id",
    refreshMaterial: "/refreshMaterial/:id",
    notFoundPage: "/notFoundPage",
};
export var PATH = {
    news: "/news/",
    events: "/events/",
    articles: "/articles/",
    vacancy: "/vacancy/",
    discount: "discount/",
    create_vacancy: "/vacancy/Create/",
    vacancy_refresh: function (id) { return "/vacancy/Refresh/".concat(id); },
    open_news_page: function (id) { return "/news/".concat(id, "/"); },
    open_events_page: function (id) { return "/events/".concat(id, "/"); },
    open_articles_page: function (id) { return "/articles/".concat(id, "/"); },
    search_result: "/search_result_page",
    company_profile: "/company_profile/",
    expert_profile: "/expert_profile",
    user_profile: "/user_profile",
    discont_profile: "/discont_profile",
    admin: "/admin",
    companies_page: "/companies_page",
    companies_serch_page: "/companies_serch_page",
    discontProfile: function (id) { return "/discontProfile/".concat(id); },
    my_subscribers_search: function (id) {
        return "/my_subscribers_search/".concat(id);
    },
    companyProfile: function (id, content) {
        return "/companyProfile/".concat(id, "/").concat(content, "/");
    },
    userProfile: function (id) { return "/userProfile/".concat(id); },
    expertProfile: function (id, content) {
        return "/expertProfile/".concat(id, "/").concat(content);
    },
    discont_create: "/discont/create/",
    discont_editDiscont: function (id) { return "/discont/editDiscont/".concat(id); },
    create: "/create",
    test_moder: "/test_moder/",
    test_moder_id: function (id) { return "/test_moder/".concat(id); },
    about: function (id) { return "/about/".concat(id); },
    notifications: "notifications/",
    resetPasswordPage: "/resetPasswordPage",
    AuthSocial: "/AuthSocial/",
    emailConfirmation: "/emailConfirmation",
    vacancy_id: function (id) { return "/vacancy/".concat(id); },
    refreshMaterial: function (id) { return "/refreshMaterial/".concat(id); },
    notFoundPage: "/notFoundPage",
};
export var LANGUAGES = [
    { name: "English", value: "en" },
    { name: "Русский", value: "ru" },
];
